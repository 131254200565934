import axios from 'axios';
import decode from 'jwt-decode';
import { IBnplData } from './interfaces/bnpl-data.interface';
import { ICheckoutData, ISessionTokenData } from './interfaces/merchant.interface';
import MerchantData from '../merchant-data/merchant-data.json';
/**
 * Merchant
 */
export default class MerchantService {
  /**
   * Get Merchant by session token
   */
  public static async getMerchantAndBnplDetailsBySessionToken(
    sessionToken: string
  ): Promise<ICheckoutData> {
    const { REACT_APP_CHECKOUT_API_URL, NODE_ENV } = process.env;

    if (NODE_ENV !== 'production' && !REACT_APP_CHECKOUT_API_URL) {
      throw new Error('Missing Environment varaible REACT_APP_CHECKOUT_API_URL');
    }

    const { data } = await axios.post<IBnplData>(
      // 'http://localhost:4600/checkout-page',
      // 'https://checkout-api.qa.optty.com/checkout-page',
      `${REACT_APP_CHECKOUT_API_URL}/checkout-page`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionToken}`,
        },
      }
    );
    return {
      bnplData: data,
      merchant: decode(sessionToken) as ISessionTokenData,
    };
  }

  public static async completeOrder(
    sessionToken: string,
    bnplName: string,
    body: any
  ): Promise<any> {
    const { REACT_APP_CHECKOUT_API_URL, NODE_ENV } = process.env;

    if (NODE_ENV !== 'production' && !REACT_APP_CHECKOUT_API_URL) {
      throw new Error('Missing Environment varaible REACT_APP_CHECKOUT_API_URL');
    }
    console.log('body', body);
    try {
      const { data } = await axios.post<IBnplData>(
        // `http://localhost:4600/order/${bnplName}`,
        // 'https://checkout-api.qa.optty.com/checkout-page',
        `${REACT_APP_CHECKOUT_API_URL}/order/${bnplName}`,
        body,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );
      return data;
    } catch (error) {
      throw new Error('Missing Environment varaible REACT_APP_CHECKOUT_API_URL');
    }
  }

  public static async getMerchantMetadataByMerchantId(merchantId: string) {
    return MerchantData.find(
      (eachMerchantData) => eachMerchantData.merchantId === merchantId
    );
  }
}
