import React, { useContext, useMemo, useState } from 'react';
import { CheckoutDataContext } from 'context/merchant-details.context';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useStyles } from './CustomerInfo';

const InvoiceDescriptionGetPaid = ({
  value,
  orderAmount,
  handlePrint,
  formData,
  setFormData,
  tipValue,
  setTipValue,
  tipError,
  setTipError,
  checkedTipping,
  setCheckedTipping,
}: any) => {
  const classes = useStyles();
  const { checkoutData } = useContext(CheckoutDataContext);
  const [showMore, setShowMore] = useState(false);
  const [tipType, setTipType] = useState('percentage');
  const currency = checkoutData?.merchant.currency || 'EUR';
  const currencyFormatter = useMemo(
    () => new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        maximumFractionDigits: 2,
      }),
    []
  );
  const handelTippingChange = () => {
    setCheckedTipping((prev: any) => !prev);
    setFormData({ ...formData, orderAmount, tipAmount: 0 });
    setTipValue('');
  };

  const handelTipAmountChange = (eValue: any) => {
    setTipValue(eValue);
    if (tipType === 'percentage') {
      const value = checkoutData?.merchant.subTotal && (checkoutData?.merchant.subTotal / 100) * parseFloat(eValue);
      if (eValue) {
        setFormData({ ...formData, orderAmount: orderAmount + value, tipAmount: value });
      } else {
        setFormData({ ...formData, orderAmount, tipAmount: 0 });
      }
    } else if (tipType === 'flat') {
      if (eValue) {
        setFormData({
          ...formData,
          tipAmount: eValue,
          orderAmount: orderAmount + parseFloat(eValue),
        });
      } else {
        setFormData({ ...formData, orderAmount, tipAmount: 0 });
      }
    }
  };

  const handelSelectChange = (e: any) => {
    setTipType(e.target.value);
    setTipValue('');
    setFormData({ ...formData, orderAmount, tipAmount: 0 });
  };

  const handleKeyDown = (event: any) => {
    const allowedKeys = [
      'Backspace',
      'Delete',
      'Tab',
      'Escape',
      'Enter',
      'ArrowLeft',
      'ArrowRight',
      'ArrowUp',
      'ArrowDown',
    ];
    if (allowedKeys.includes(event.key)) {
      return;
    }
    if (
      (event.ctrlKey || event.metaKey) && (event.key === 'a' || event.key === 'c' || event.key === 'v' || event.key === 'x')
    ) {
      return;
    }
    if (!/^[0-9.]$/.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <section className="flex box flex-between mobile-flex" id="print-desc">
      <div className="w-half mobile-w-full">
        <div style={{ padding: '5px' }}>
          <span className="little-bold font-large  mr-8">Invoice</span>
          <span className={`${classes.blueSpan} font-large`}>
            #{checkoutData?.merchant.invoiceNumber}
          </span>
        </div>
        {value === 0 ? (
          ''
        ) : (
          <div style={{ padding: '5px' }}>
            <span className="little-bold font-large mr-8">Order Amount</span>
            <span className={`${classes.blueSpan} font-large`}>
              {currencyFormatter.format(orderAmount as any)}
            </span>
          </div>
        )}
        <div style={{ padding: '5px' }}>
          <span className="">Due Date:</span>
          <span>{` ${checkoutData?.merchant.dueDate}`}</span>
        </div>
        {value === 0 && checkoutData?.merchant.enableTipping ? (
          <Box display="flex">
            <Typography padding={0.5} variant="body1">
              Want to give Tip
            </Typography>
            <Switch
              checked={checkedTipping}
              color="primary"
              onChange={handelTippingChange}
            />
          </Box>
        ) : (
          ''
        )}
        {checkedTipping ? (
          <Box ml={0.5} mt={1}>
            <FormControl
              style={{ minWidth: '150px', marginRight: '30px' }}
              variant="outlined"
            >
              <InputLabel id="demo-simple-select-outlined-label">Tip Type</InputLabel>
              <Select
                id="demo-simple-select-outlined"
                label="Tip Type"
                labelId="demo-simple-select-outlined-label"
                onChange={(e) => handelSelectChange(e)}
                value={tipType}
              >
                <MenuItem value="percentage">Percent</MenuItem>
                <MenuItem value="flat">Flat</MenuItem>
              </Select>
            </FormControl>
            <TextField
              error={Boolean(tipError)}
              helperText={tipError}
              label="Add Tip"
              onChange={(e: any) => {
                handelTipAmountChange(
                  e.target.value.includes('.')
                    ? e.target.value.slice(0, e.target.value.indexOf('.') + 3)
                    : e.target.value
                );
                setTipError('');
              }}
              onKeyDown={handleKeyDown}
              value={tipValue}
              variant="outlined"
            />
          </Box>
        ) : (
          ''
        )}
        {value === 2 ? (
          <>
            <button
              className="colored-btn pointer padding-large w-half mt-10 m-w-full small-screen-off"
              id="printBtn"
              onClick={() => {
                handlePrint();
              }}
              type="button"
            >
              Print Receipt
            </button>
          </>
        ) : (
          ''
        )}
        <h1 className="only-show-on-print ml-5" style={{ display: 'none' }}>
          <span className="little-bold font-large mr-8">Merchant Name:</span>
          <span className="font-large">{checkoutData?.merchant.merchantName}</span>
        </h1>
      </div>

      <div className="invoice-description w-half mobile-w-full m-ml-5">
        <div>
          <span className="little-bold font-large">Descprition</span>
        </div>
        <div className="mt-5">
          <span className="invoice-description-span color-light">
            {checkoutData?.merchant.description && checkoutData?.merchant.description.length < 60 ? (
              checkoutData?.merchant?.description
            ) : (
              <>
                {showMore
                  ? checkoutData?.merchant?.description
                  : `${checkoutData?.merchant?.description.substring(0, 50)}`}
                <Typography
                  color="primary"
                  onClick={() => setShowMore(!showMore)}
                  sx={{ cursor: 'pointer' }}
                >
                  {showMore ? 'Show less' : 'Show more'}
                </Typography>
              </>
            )}
          </span>
        </div>
      </div>
    </section>
  );
};

export default InvoiceDescriptionGetPaid;
