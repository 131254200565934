import React, { useContext } from 'react';
import { CheckoutDataContext } from 'context/merchant-details.context';
import footerStyles from './Footer.module.css';

const Footer: React.FC = () => {
  const { checkoutData } = useContext(CheckoutDataContext);
  const currentYear = new Date().getFullYear();

  return (
    <footer
      className={footerStyles.footer}
      style={{ backgroundColor: `${checkoutData?.merchant.primaryColor}` }}
    >
      <p className={footerStyles.footerCopywright}>
        Copyright. All Rights Reserved,{' '}
        {checkoutData ? checkoutData?.merchant.merchantName : ''} {currentYear}
      </p>
      {/* <div className={`${footerStyles.footerMisc} m-min-w`}>
        <p>
          Contact{' '}
          <a data-underline="true" href={merchantData?.supportContactEmail || ''}>
            {merchantData?.supportContactEmail || ''}
          </a>{' '}
          for support
        </p>{' '}
        <span className={footerStyles.divider} />
        <a
          data-underline="true"
          href={merchantData?.termsOfServiceUrl || ''}
          rel="noopener noreferrer"
          target="_blank"
        >
          Terms &amp; Conditions
        </a>{' '}
        <span className={footerStyles.divider} />
        <a
          data-underline="true"
          href={merchantData?.privacyPolicyUrl || ''}
          rel="noopener noreferrer"
          target="_blank"
        >
          Privacy Policy
        </a>
      </div> */}
    </footer>
  );
};

export default Footer;
