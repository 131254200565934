import PageWrapper from 'components/PageWrapper/PageWrapper';
import { CheckoutDataContext } from 'context/merchant-details.context';
import React, { useContext } from 'react';
import styles from './PageContent.module.css';

type IPageContentProps = React.HTMLProps<HTMLDivElement>;

export const PageContent: React.FC<IPageContentProps> = ({ children }) => {
  const { isLoading } = useContext(CheckoutDataContext);
  return (
    <div className={styles.pageContent}>
      <PageWrapper hasBackButton={!isLoading}>{children}</PageWrapper>
    </div>
  );
};
