import ErrorComponent from 'components/Error/ErrorComponent';
import { CheckoutDataContext } from 'context/merchant-details.context';
import React, { useContext, useEffect } from 'react';
import BnplListItem from './BnplItem';
import bnplListStyles from './BnplList.module.css';

const BnplList: React.FC<{}> = () => {
  const {
    selectedBnpl,
    checkoutData,
    completeOrder,
    createOrderError,
    isCompletingOrder,
    isAmountChange,
    setIsAmountChange,
  } = useContext(CheckoutDataContext);

  useEffect(() => {
    if (checkoutData?.merchant.paymentType === 'GHPP') {
      setIsAmountChange(true);
    }
  }, []);

  return (
    <div className={bnplListStyles.bnplListContainer}>
      <div className={bnplListStyles.bnplListTitle}>
        {checkoutData?.bnplData.bnpls.length
          ? 'Select Payment Option'
          : 'There are no payment methods available for your order'}
      </div>
      <div className={bnplListStyles.bnplListSubtitle}>
        {checkoutData?.bnplData.bnpls.length
          ? 'Select a Payment provider below to continue and pay for your order in installments'
          : 'Please go back and choose another form of payment.'}
      </div>
      <div className={bnplListStyles.bnplsList}>
        {checkoutData?.bnplData.bnpls.map((bnpl) => (
          <BnplListItem bnpl={bnpl} key={bnpl.name} />
        ))}
      </div>
      {createOrderError && !isCompletingOrder && (
        <ErrorComponent
          headerText="Payment Failed"
          subText="Payment failed due to a connection error"
        />
      )}
      <div className={bnplListStyles.buttonContainer}>
        <button
          className={bnplListStyles.button}
          disabled={!selectedBnpl || isCompletingOrder || isAmountChange}
          onClick={() => completeOrder()}
          type="button"
        >
          {isCompletingOrder && selectedBnpl ? 'Completing  Order...' : 'Continue & Pay'}
        </button>
      </div>
    </div>
  );
};

export default BnplList;
