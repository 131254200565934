import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const steps = ['Customer Details', 'Payment Details', 'Order Details'];

export default function GetPaidStepper({ value }: any) {
  return (
    <div className="flex flex-between">
      <Box className="mb-20 w-90" ml={3} mr={3}>
        <Stepper
          activeStep={value}
          alternativeLabel
          nonLinear
          sx={{
            '.MuiStepConnector-root': {
              top: 13,
            },
            '.MuiStepConnector-root span': {
              borderColor: 'transparent',
            },
            '.MuiStepConnector-root span::before': {
              display: 'flex',
              justifyContent: 'center',
              content: '">"',
            },
          }}
        >
          {steps.map((label) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps} icon={<span />}>
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
    </div>
  );
}
