import { createContext } from 'react';
import { Bnpl } from 'services/interfaces/bnpl-data.interface';
import { IMerchantData } from 'services/interfaces/merchant-data.interface';
import { ICheckoutData } from 'services/interfaces/merchant.interface';
import { OrderPayload } from 'services/interfaces/order-payload.interface';

interface ICheckoutDataContext {
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  checkoutData?: ICheckoutData;
  selectedBnpl?: Bnpl;
  setSelectedBnpl: (bnpl: Bnpl | undefined) => void;
  completeOrder: () => void;
  isCompletingOrder: boolean;
  error?: Error;
  setError: (value: unknown) => void;
  createOrderError?: Error | unknown;
  merchantData?: IMerchantData;
  orderData?: OrderPayload;
  changeOrderAmount: (value: any) => void;
  isAmountChange?: boolean;
  setIsAmountChange: (value: boolean) => void;
  formError?: any;
}

export const CheckoutDataContext = createContext<ICheckoutDataContext>({
  isLoading: true,
  setSelectedBnpl: () => {},
  setIsLoading: () => {},
  completeOrder: () => {},
  setError: () => {},
  isCompletingOrder: false,
  changeOrderAmount: () => {},
  setIsAmountChange: () => {},
});
