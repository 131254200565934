import Emptystar from '../../assets/images/empty-star.png';
import Fullstar from '../../assets/images/full-star.png';

export const isValidURL = (str: string) => {
  if (!str || str === '') return false;
  const pattern = new RegExp(
    '^(https?:\\/\\/)?'
      + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'
      + '((\\d{1,3}\\.){3}\\d{1,3}))'
      + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'
      + '(\\?[;&a-z\\d%_.~+=-]*)?'
      + '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
};

export const translateTermUnit = (unit: string) => {
  if (unit.toLowerCase() === 'weeks') {
    return 'weekly';
  }
  if (unit.toLowerCase() === 'months') {
    return 'monthly';
  }
  return unit;
};

export const showInterestRate = (rate: string, interestRateAllowed: boolean) => {
  let classNames = '';
  if (interestRateAllowed) {
    if (Number(rate) === 0) {
      classNames = 'green-text';
    } else if (Number(rate) > 0) {
      classNames = 'red-text';
    } else {
      classNames = 'grey-text';
    }
  } else {
    classNames = 'grey-text';
  }

  const text = !interestRateAllowed
    ? 'check with provider'
    : `${Number(rate).toFixed(2)}%`;
  return `<span class="${classNames}">${text}</span>`;
};

export const decideTextFees = (fees: string, text: string) => {
  if (fees?.toLowerCase() === 'enabled') {
    return 'Yes';
  }
  if (fees?.toLowerCase() === 'disabled') {
    return 'No';
  }
  return text;
};

export function openGenericModal(
  id: string,
  className: string,
  modalContent: string,
  title = 'Terms and Conditions',
  logoImage = undefined
) {
  const modal = document.createElement('div');
  modal.setAttribute('class', className);
  if (id) modal.setAttribute('id', id);

  const modalBody = document.createElement('div');
  modalBody.setAttribute('class', 'modalBody');

  modal.appendChild(modalBody);
  document.body.appendChild(modal);

  const responsiveness = `
      .modalBody p{
        word-break: break-word; 
      }
      @media (max-width: 896px) {
            .modalBody{
                max-width: 70% !important;
                max-height: 70vh !important;
                margin: 15vh auto !important;
            }
        }
        @media (max-width: 414px) {
            .modalBody{
                max-width: 90% !important;
                max-height: 80vh !important;
                margin: 10vh auto !important;
            }
        }
    `;
  const responsiveStyle = document.createElement('style');
  responsiveStyle.innerHTML = responsiveness;
  modal.prepend(responsiveStyle);

  const closeButton = `
      <button id='close-terms-modal' style='background: transparent; text-align: right; border: none; font-size: 20px; font-weight: 500; line-height: 1; color: #000000; opacity: 1.0; cursor: pointer; margin: 0 8px 0 0; box-shadow: none'>
        x
      </button>`;

  const modalHeader = `
      <div style='display: flex; justify-content: space-between; align-items: baseline; width: 100%; margin: 0;'>
        <div style='width: 100%; font-weight: 600; font-size: 18px; color: #000;'>${title}</div>
        ${closeButton}
      </div>
    `;

  if (logoImage) {
    modalBody.style.padding = '0';
  }

  modalBody.innerHTML = modalHeader;
  modalBody.innerHTML += `<div style="padding: 0 10px 10px">${modalContent}</div>`;

  const closeBtn = document.getElementById('close-terms-modal');
  closeBtn?.addEventListener('click', () => {
    modal.remove();
  });

  return false;
}

export const showFees = (feesAllowed: boolean, fees: string, feesCustomText: string) => {
  return `<span>${
    !feesAllowed ? 'Check with provider' : decideTextFees(fees, feesCustomText)
  }</span>`;
};

export const renderIframe = (editor: string, iframeUrl: string) => {
  if (!iframeUrl || !editor.includes('%iframe') || !isValidURL(iframeUrl)) {
    return '';
  }
  return `<iframe src=${iframeUrl} width='100%' height='100%' style='border: none'></iframe>`;
};

export const addBnplRatings = (bnplRating: number) => {
  return `
          ${[...Array(5).keys()]
            .map((each) => {
              if (each < bnplRating) {
                return `<img src =${Fullstar} class="bnpl-star" alt="Star rating" />`;
              }
              return `<img src =${Emptystar} class="bnpl-star" alt="Star rating" />`;
            })
            .reduce((acc, curr) => {
              return acc + curr;
            }, '')}
      `;
};

export const renderRating = (rating: number) => {
  return `
      <style>
      .ratings {
        margin: 20px 0;
      }
      .bnpl-star {
          width: auto;
          height: auto;
          max-width: 18px !important;
          max-height: 17px !important;
          min-width: auto !important;
        vertical-align: top;
      }
      </style>
          ${addBnplRatings(rating)}
      `;
};

export const getCurrencySymbol = (currency: string) => {
  const symbol = new Intl.NumberFormat('en', { style: 'currency', currency })
    .formatToParts(1)
    .find((part) => part.type === 'currency')?.value;
  return symbol || 'NIL';
};
