// import { CheckoutDataContext } from 'context/merchant-details.context';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CheckoutDataContext } from 'context/merchant-details.context';
import CustomerInfo from './CustomerInfo';
import PaymentDetails from './PaymentDetails';
import OrderDetails from './OrderDetails';

interface FormData {
  fname: string;
  lname: string;
  email: any;
  phoneNumber: any;
  streetAddress: string;
  streetAddress2: string;
  city: string;
  country: string;
  region: string;
  state: string;
  postalCode: string;
  companyName: string;
  orderAmount: any;
  tipAmount: any;
}

const GetPaidIndex = () => {
  const [value, setValue] = useState<any>(0);
  const { checkoutData } = useContext(CheckoutDataContext);
  const [formData, setFormData] = useState<FormData>({
    fname: '',
    lname: '',
    email: '',
    phoneNumber: '',
    streetAddress: '',
    streetAddress2: '',
    city: '',
    country: '',
    region: '',
    state: '',
    postalCode: '',
    companyName: '',
    orderAmount: checkoutData?.merchant.orderAmount,
    tipAmount: '',
  });

  useEffect(() => {
    if (checkoutData?.merchant.paymentType === 'CHPP') {
      setFormData({
        ...formData,
        orderAmount: checkoutData?.merchant.orderAmount,
        email: checkoutData?.merchant.email,
        phoneNumber: checkoutData?.merchant.phoneNumber,
      });
    } else if (checkoutData?.merchant.paymentType === 'GHPP') {
      setFormData({
        ...formData,
        orderAmount: checkoutData?.merchant.orderAmount,
      });
    }
  }, [checkoutData]);

  const location = useLocation();
  const selectedTab = location.pathname.split('/')[3];
  useEffect(() => {
    if (selectedTab) {
      setValue(selectedTab);
    }
  }, [value, selectedTab]);

  return (
    <>
      {value === 0 && (
        <CustomerInfo
          formData={formData}
          setFormData={setFormData}
          setValue={setValue}
          value={value}
        />
      )}
      {value === 1 && (
        <PaymentDetails
          formData={formData}
          setFormData={setFormData}
          setValue={setValue}
          value={value}
        />
      )}
      {value === 2 && <OrderDetails value={value} />}
    </>
  );
};

export default GetPaidIndex;
