import React, { ReactNode } from 'react';
import BackButton from 'components/BackButton/BackButton';
import PageWrapperStyles from './PageWrapper.module.css';

const PageWrapper = ({
  children,
  hasBackButton,
}: {
  children?: ReactNode;
  hasBackButton?: boolean;
}) => {
  return (
    <div className={PageWrapperStyles.wrapper}>
      {hasBackButton ? (
        <div className={PageWrapperStyles.backButton}>
          {' '}
          <BackButton />{' '}
        </div>
      ) : null}{' '}
      {children}
    </div>
  );
};

export default PageWrapper;
