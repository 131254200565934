import React from 'react';
import containerStyles from './MessageContainer.module.css';

interface IMessageContainerProps {
  icon: string;
  headerText: string;
  subText?: string;
  isLoader?: boolean;
}

const MessageContainer: React.FC<IMessageContainerProps> = ({
  icon,
  headerText,
  subText,
  isLoader,
}) => {
  return (
    <div className={containerStyles.messageContainer}>
      <img alt="icon" className={isLoader ? containerStyles.loader : ''} src={icon} />
      <h3>{headerText}</h3>
      <p>{subText}</p>
    </div>
  );
};

export default MessageContainer;
