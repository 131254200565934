import { CheckoutDataContext } from 'context/merchant-details.context';
import React, { useContext, useMemo } from 'react';
import orderAmountStyles from './OrderAmount.module.css';

const OrderAmountCard: React.FC<{}> = () => {
  const { checkoutData, changeOrderAmount } = useContext(CheckoutDataContext);

  // Workaround for getting the currency, since it isnt in the token
  const currency = useMemo(
    () => (checkoutData?.bnplData.bnpls.length
        ? checkoutData?.bnplData.bnpls[0].currency.toUpperCase() // picks the first item on the bnpl data arr
        : 'GBP'),
    [checkoutData]
  );

  const currencyFormatter = useMemo(
    () => new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        maximumFractionDigits: 2,
      }),
    []
  );

  return (
    <>
      <div className={orderAmountStyles.orderAmountCard}>
        <div className={orderAmountStyles.orderAmountDiv}>
          <span className={orderAmountStyles.orderAmountTitle}>Order Amount </span>
          {checkoutData?.merchant.paymentType !== 'GHPP' ? (
            <span className={orderAmountStyles.orderAmount}>
              {checkoutData?.bnplData.bnpls.length
                ? currencyFormatter.format(checkoutData?.merchant.orderAmount as any)
                : (checkoutData?.merchant.orderAmount as any)}
            </span>
          ) : (
            <span className={orderAmountStyles.orderAmount}>
              {
                currencyFormatter.formatToParts(
                  checkoutData?.merchant.orderAmount as any
                )[0].value
              }{' '}
              <input
                className={orderAmountStyles.amount}
                id="amount"
                name="amount"
                onChange={changeOrderAmount}
                placeholder="Enter amount"
                type="text"
              />
            </span>
          )}
        </div>
        {checkoutData && 'description' in checkoutData.merchant && (
          <>
            <hr className={orderAmountStyles.hr} />
            <div className={orderAmountStyles.orderDescriptionDiv}>
              <div className={orderAmountStyles.orderAmountTitle}>Description</div>
              <div className={orderAmountStyles.description}>
                {checkoutData?.merchant.description as any}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default OrderAmountCard;
