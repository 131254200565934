import { CheckoutDataContext } from 'context/merchant-details.context';
import React, { useContext, useState } from 'react';
import {
    Box,
    Checkbox,
    CheckboxProps,
    TextField,
    Typography
} from '@mui/material';
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import { makeStyles, withStyles } from '@mui/styles';
import MessageContainer from 'components/MessageContainer/MessageContainer';
import Loader from '../../assets/images/loader.svg';
import GetPaidStepper from './GetPaidStepper';
import './GetPaid.css';
import OrderSummary from './OrderSummary';
import InvoiceDescriptionGetPaid from './InvoiceDescriptionGetPaid';
import 'react-phone-number-input/style.css';
import InvoiceDescriptionQuickLink from './InvoiceDescriptionQuickLink';

export const useStyles = makeStyles({
  title: {
    fontSize: '30px',
    fontWeight: '600',
  },
  caption: {
    color: '#4E5862',
    marginTop: '5px',
    marginBottom: '20px',
  },
  common: {
    borderRadius: '10px',
    backgroundColor: 'white',
  },
  halfWidth: {
    width: '48%',
  },
  samllWidth: {
    width: '28%',
  },
  checkboxAndBtn: {
    paddingTop: '20px',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '20px',
  },
  blueSpan: {
    fontWeight: 600,
    color: '#3837ea',
  },
  bnplsList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: '24px',
  },
  countyCodeTextBox: {
    width: '16%',
    marginRight: '20px !important',
  },
  helperText: {
    padding: '3px 15px',
  },
  phoneNumberBox: {
    maxHeight: '57px',
    border: '1px solid #C4C4C4',
    borderRadius: '7px',
    backgroundColor: 'white',
    position: 'relative',
    '&:hover': {
      border: '1px solid black',
    },
    '&:focus': {
      border: '1px solid #3F51B5',
    },
    '&:focus::placeholder': {
      color: '#3F51B5',
    },
  },
  phoneNumber: {
    '& .PhoneInputCountry': {
      paddingLeft: '15px',
    },
    '& .PhoneInputInput': {
      paddingLeft: '10px',
      marginRight: '3px',
      border: 'none',
    },
    '& .PhoneInputInput:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  formControl: {
    minWidth: '120px',
  },
});

export const BlackCheckbox = withStyles({
  root: {
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const CustomerInfo = ({
    value,
    setValue,
    formData,
    setFormData
  }: any) => {
  const classes = useStyles();
  const { checkoutData } = useContext(CheckoutDataContext);
  const { isLoading, setIsLoading } = useContext(CheckoutDataContext);
  // const [tipAmount, setTipAmount] = useState<number>(0);
  const [tipValue, setTipValue] = useState('');
  const [tipError, setTipError] = useState('');
  const [checkedTipping, setCheckedTipping] = useState(false);
  const [errors, setErrors] = useState({
    fname: false,
    lname: false,
    streetAddress: false,
    city: false,
    country: false,
    region: false,
    state: false,
    postalCode: false,
    phoneNumber: false,
    email: false,
    orderAmount: false,
  });

  const handleClick = () => {
    setIsLoading(true);
    const formValid = validateForm();
    const tipValid = tipValidation();
    if (formValid && tipValid) {
      setValue((prevActiveStep: number) => prevActiveStep + 1);
    }
    setIsLoading(false);
  };

  const tipValidation = () => {
    let valid = true;
    if (checkedTipping) {
      if (checkedTipping && tipValue.trim() === '') {
        setTipError('Tip amount is required');
        valid = false;
      } else if (!(/^-?(?:\d+\.\d+|\d+)$/.test(tipValue) && parseFloat(tipValue) > 0)) {
        setTipError('Tip must be greater than Zero');
        valid = false;
      } else {
        setTipError('');
      }
    }
    return valid;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: false });
  };

  const validateForm = () => {
    let valid = true;
    const currenctErrors: any = {};
    Object.keys(errors).forEach((fieldName) => {
      if (fieldName === 'country' || fieldName === 'city') {
        if (!/^[a-zA-Z\s]+$/.test(formData[fieldName])) {
          currenctErrors[fieldName] = true;
          valid = false;
        }
      } else if (fieldName === 'email') {
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData[fieldName])) {
          currenctErrors[fieldName] = true;
          valid = false;
        }
      } else if (fieldName === 'phoneNumber') {
        if (!(formData.phoneNumber && isValidPhoneNumber(formData.phoneNumber))) {
          currenctErrors[fieldName] = true;
          valid = false;
        }
      } else if (fieldName === 'orderAmount') {
        if (checkoutData?.merchant.paymentType === 'GHPP') {
          const minAmountFlag = Number(formData.orderAmount) >= Number(checkoutData?.merchant.minAmount);
          const maxAmountFlag = Number(formData.orderAmount) <= Number(checkoutData?.merchant.maxAmount);
          const regExp = /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/;
          if (!regExp.test(formData.orderAmount)) {
            currenctErrors[fieldName] = true;
            valid = false;
          } else if (!(minAmountFlag && maxAmountFlag)) {
            currenctErrors[fieldName] = true;
            valid = false;
          }
        }
      } else if (!formData[fieldName]) {
        currenctErrors[fieldName] = true;
        valid = false;
      }
    });
    setErrors(currenctErrors);
    return valid;
  };

  return (
    <>
      {isLoading ? (
        <MessageContainer
          headerText="Loading..."
          icon={Loader}
          isLoader
          subText="Please do not refresh the page"
        />
      ) : (
        checkoutData && (
          <div className="flex flex-center mb-60">
            <div className="main-checkout mb-20">
              <GetPaidStepper value={value} />

              {/* Invoice and tip sectionn */}

              {checkoutData?.merchant.paymentType === 'CHPP' ? (
                <InvoiceDescriptionGetPaid
                  checkedTipping={checkedTipping}
                  formData={formData}
                  orderAmount={checkoutData?.merchant.orderAmount}
                  setCheckedTipping={setCheckedTipping}
                  setFormData={setFormData}
                  setTipError={setTipError}
                  setTipValue={setTipValue}
                  tipError={tipError}
                  tipValue={tipValue}
                  value={value}
                />
              ) : (
                <InvoiceDescriptionQuickLink
                  errors={errors}
                  formData={formData}
                  orderAmount={formData.orderAmount}
                  setErrors={setErrors}
                  setFormData={setFormData}
                  value={value}
                />
              )}
              <div
                className={`${
                  checkoutData?.merchant.paymentType === 'GHPP' && 'justify-content-center'
                } mt-70 flex mobile-flex mb-20 flex-reverse`}
              >
                {/* Name And Address Form */}
                <div className="box checkout-address-form mobile-w-full m-m-70">
                  <div className="flex flex-between">
                    <span className={`${classes.title} m-tile`}>Name & Address</span>
                    <div>
                      <span className="m-span">
                        Currency: {checkoutData?.merchant.currency}
                      </span>
                    </div>
                  </div>

                  <div>
                    <Box className="flex flex-between" mt={2}>
                      <TextField
                        className={`${classes.common} ${classes.halfWidth} mh-2 m-main-label-size`}
                        error={errors.fname}
                        helperText={errors.fname ? 'First Name is required ' : null}
                        id="outlined-basic"
                        label="First Name *"
                        name="fname"
                        onChange={handleChange}
                        value={formData?.fname}
                        variant="outlined"
                      />
                      <TextField
                        className={`${classes.common} ${classes.halfWidth} mh-2 m-main-label-size`}
                        error={errors.lname}
                        helperText={errors.lname ? 'Last Name is required ' : null}
                        id="outlined-basic"
                        label="Last Name *"
                        name="lname"
                        onChange={handleChange}
                        value={formData?.lname}
                        variant="outlined"
                      />
                    </Box>
                    <Box mt={3}>
                      <TextField
                        className={`${classes.common} mh-2 m-main-label-size`}
                        error={errors.streetAddress}
                        fullWidth
                        helperText={errors.streetAddress ? 'Address is required ' : null}
                        id="outlined-basic"
                        label="Address line 1 *"
                        name="streetAddress"
                        onChange={handleChange}
                        value={formData?.streetAddress}
                        variant="outlined"
                      />
                      <TextField
                        className={`${classes.common} mh-2 mt-20 m-main-label-size`}
                        fullWidth
                        id="outlined-basic"
                        label="Address line 2 (Optional)"
                        name="streetAddress2"
                        onChange={handleChange}
                        value={formData?.streetAddress2}
                        variant="outlined"
                      />

                      <TextField
                        className={`${classes.common} mh-2 mt-20 mw-full m-margin-top m-main-label-size`}
                        error={errors.country}
                        fullWidth
                        helperText={errors.country ? 'Country is required' : null}
                        id="outlined-basic"
                        label="Country *"
                        name="country"
                        onChange={handleChange}
                        value={formData?.country}
                        variant="outlined"
                      />

                      <Box className="flex flex-between m-fields m-margin-0" mt={2}>
                        <TextField
                          className={`${classes.common} ${classes.halfWidth} mh-2 mw-full m-margin-top m-main-label-size`}
                          error={errors.region}
                          helperText={errors.region ? 'Region is required' : null}
                          id="outlined-basic"
                          label="Region *"
                          name="region"
                          onChange={handleChange}
                          value={formData?.region}
                          variant="outlined"
                        />
                        <TextField
                          className={`${classes.common} ${classes.halfWidth} mh-2 mw-full m-margin-top m-main-label-size`}
                          error={errors.state}
                          helperText={errors.state ? 'State is required' : null}
                          id="outlined-basic"
                          label="State *"
                          name="state"
                          onChange={handleChange}
                          value={formData?.state}
                          variant="outlined"
                        />
                      </Box>

                      <Box className="flex flex-between m-fields m-margin-0" mt={2}>
                        <TextField
                          className={`${classes.common} ${classes.halfWidth} mh-2 mw-full m-margin-top m-main-label-size`}
                          error={errors.city}
                          helperText={errors.city ? 'City is required' : null}
                          id="outlined-basic"
                          label="City *"
                          name="city"
                          onChange={handleChange}
                          value={formData?.city}
                          variant="outlined"
                        />
                        <TextField
                          className={`${classes.common} ${classes.halfWidth} mh-2 mw-full m-margin-top m-main-label-size`}
                          error={errors.postalCode}
                          helperText={
                            errors.postalCode ? 'Postal Code is required ' : null
                          }
                          id="outlined-basic"
                          label="Postal Code *"
                          name="postalCode"
                          onChange={handleChange}
                          value={formData?.postalCode}
                          variant="outlined"
                        />
                      </Box>
                    </Box>
                  </div>
                  {checkoutData?.merchant.paymentType === 'CHPP' && (
                    <Box mt={3}>
                      <span className={`${classes.title} m-tile`}>
                        Contact Information
                      </span>
                      <Box
                        className="flex m-fields m-margin-0"
                        justifyContent="start"
                        mt={2}
                      >
                        <TextField
                          className={`${classes.common} ${classes.halfWidth} mh-2 mw-full m-margin-top m-main-label-size`}
                          disabled
                          id="outlined-basic"
                          label="Phone Number"
                          name="phoneNumber"
                          onChange={handleChange}
                          value={formData.phoneNumber}
                          variant="outlined"
                        />
                      </Box>
                      <Box mt={3}>
                        <TextField
                          className={`${classes.common} mh-2`}
                          disabled
                          fullWidth
                          id="outlined-basic"
                          label="Email"
                          name="email"
                          onChange={handleChange}
                          value={formData?.email}
                          variant="outlined"
                        />
                      </Box>
                      <div className="mt-20">
                        <button
                          className="colored-btn pointer padding-large w-full"
                          onClick={handleClick}
                          type="button"
                        >
                          Continue to payment details
                        </button>
                      </div>
                    </Box>
                  )}

                  {checkoutData?.merchant.paymentType === 'GHPP' && (
                    <Box mt={3}>
                      <span className={`${classes.title} m-tile`}>
                        Contact Information
                      </span>
                      <Box
                        className={`${classes.phoneNumberBox} ${classes.halfWidth} mw-full`}
                        mt={3}
                        style={{ borderColor: errors.phoneNumber ? 'red' : '#dfe3e9' }}
                      >
                        <PhoneInput
                          className={`${classes.phoneNumber} phone-number m-phone-number override-mui-style m-main-label-size`}
                          name="phoneNumber"
                          onChange={(phoneNumber) => {
                            setFormData({
                              ...formData,
                              phoneNumber: formatPhoneNumberIntl(phoneNumber),
                            });
                            setErrors({ ...errors, phoneNumber: false });
                          }}
                          placeholder="Phone Number"
                          value={formData.phoneNumber}
                        />
                        <Typography
                          className={`${classes.helperText} override-mui-style`}
                          color="error"
                          variant="caption"
                        >
                          {`${
                            errors.phoneNumber ? 'Please enter valid phone number' : ''
                          }`}
                        </Typography>
                      </Box>
                      <Box mt={4}>
                        <TextField
                          className={`${classes.common} mh-2`}
                          error={errors.email}
                          fullWidth
                          helperText={errors.email ? 'Please enter valid email' : null}
                          id="outlined-basic"
                          label="Email"
                          name="email"
                          onChange={handleChange}
                          value={formData?.email}
                          variant="outlined"
                        />
                      </Box>
                      <div className="mt-20">
                        <button
                          className="colored-btn pointer padding-large w-full"
                          onClick={handleClick}
                          type="button"
                        >
                          Continue to payment details
                        </button>
                      </div>
                    </Box>
                  )}
                </div>
                {/* Order Summary */}
                {checkoutData?.merchant.paymentType === 'CHPP' && (
                  <OrderSummary
                    formData={formData}
                    subTotal={checkoutData?.merchant.subTotal}
                    taxAmount={checkoutData?.merchant.taxAmount}
                    value={value}
                  />
                )}
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default CustomerInfo;
