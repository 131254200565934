import React from 'react';
import { useNavigate } from 'react-router-dom';
import backButtonImg from '../../assets/images/arrow_back.svg';
import backButtonStyles from './BackButton.module.css';

const BackButton: React.FC = () => {
  const navigate = useNavigate();
  const handleGoback = () => {
    navigate(-1);
  };
  return (
    <div
      className={backButtonStyles.backButton}
      onClick={handleGoback}
      onKeyPress={() => {}}
      role="button"
      tabIndex={0}
    >
      <img alt="back" className={backButtonStyles.backButtonIcon} src={backButtonImg} />
      <p>Back</p>
    </div>
  );
};

export default BackButton;
