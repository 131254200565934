import { Error } from 'services/interfaces/checkout-validation.interface';

export default class Validator {
  public static checkoutForm(inputValues: Record<string, string>) {
    const errors: Error = {};
    if (
      !inputValues
      || inputValues?.fname === undefined
      || inputValues?.fname?.length < 2
    ) {
      errors.fname = 'First name is too short';
    }
    if (
      !inputValues
      || inputValues?.lname === undefined
      || inputValues?.lname?.length < 2
    ) {
      errors.lname = 'Last name is too short';
    }
    // eslint-disable-next-line no-useless-escape
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{1,15}$/;
    if (
      !inputValues
      || inputValues?.email === undefined
      || !emailRegex.test(inputValues?.email)
    ) {
      errors.email = 'Email must be valid';
    }
    // const phoneRegex2 = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    if (!inputValues || inputValues?.phoneNumber === undefined) {
      errors.phoneNumber = 'Phone Number must be valid';
    }
    if (
      !inputValues
      || inputValues?.streetAddress === undefined
      || inputValues?.streetAddress?.length < 2
    ) {
      errors.streetAddress = 'Street Address is too short';
    }
    if (
      !inputValues
      || inputValues?.city === undefined
      || inputValues?.city?.length < 2
    ) {
      errors.city = 'City name is too short';
    }
    if (
      !inputValues
      || inputValues?.country === undefined
      || inputValues?.country?.length < 2
    ) {
      errors.country = 'Country name is too short';
    }
    return errors;
  }
}
