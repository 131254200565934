import { CheckoutDataContext } from 'context/merchant-details.context';
import React, { useContext, useEffect, useState } from 'react';
import { Bnpl } from 'services/interfaces/bnpl-data.interface';
import parseBnplContent from 'util/contentParser';
import CaretDown from '../../assets/images/caretDown.svg';
import CaretUp from '../../assets/images/caretUp.svg';
import bnplItemStyles from './BnplItem.module.css';

interface IBnplItemProps {
  bnpl: Bnpl;
  setDisableBtn: any;
  disableBtn: boolean;
  caller: string;
}

const BnplListItem: React.FC<IBnplItemProps> = ({
  bnpl,
  disableBtn,
  setDisableBtn,
  caller,
}) => {
  const { selectedBnpl, setSelectedBnpl } = useContext(CheckoutDataContext);
  const isActive = selectedBnpl?.name === bnpl.name;
  const [content, setContent] = useState('');

  useEffect(() => {
    if (selectedBnpl?.name === bnpl.name) {
      parseBnplContent(selectedBnpl).then((content) => {
        setContent(content);
      });
      if (selectedBnpl && caller === 'optty-direct') setDisableBtn(!disableBtn);
    }
  }, [selectedBnpl]);

  const handleClick = () => {
    setSelectedBnpl(selectedBnpl?.name === bnpl.name ? undefined : bnpl);
    if (selectedBnpl) setDisableBtn(!disableBtn);
  };

  return (
    <div
      className={
        isActive
          ? bnplItemStyles.bnplListItemContainerFull
          : bnplItemStyles.bnplListItemContainer
      }
    >
      <div
        aria-hidden="true"
        className={bnplItemStyles.bnplHeader}
        onClick={() => handleClick()}
      >
        <div className={bnplItemStyles.bnplTitleContainer}>
          <span className={bnplItemStyles.checkbox}>
            <span className={isActive ? bnplItemStyles.checkboxActive : ''} />
          </span>
          <img alt={bnpl.name} className={bnplItemStyles.bnplImage} src={bnpl.imageUrl} />
        </div>

        {isActive ? (
          <img alt="caret up" src={CaretUp} />
        ) : (
          <img alt="caret up" src={CaretDown} />
        )}
      </div>
      {isActive && (
        <div className={bnplItemStyles.bnplDetails}>
          <div className={bnplItemStyles.content}>
            <div
              className={bnplItemStyles.bnplCheckoutConfig}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default BnplListItem;
