import React, { useEffect, useRef } from 'react';
import icon from '../../assets/images/error.svg';
import styles from './ErrorComponent.module.css';

interface IErrorComponentProps {
  headerText: string;
  subText: string;
}

const ErrorComponent: React.FC<IErrorComponentProps> = ({ headerText, subText }) => {
  const errorContainerElement = useRef(null);
  useEffect(() => {
    (errorContainerElement.current as any).scrollIntoView();
  }, []);
  return (
    <div className={styles.errorContainer} ref={errorContainerElement}>
      <img alt="icon" src={icon} />
      <h3>{headerText}</h3>
      <p>{subText}</p>
    </div>
  );
};

export default ErrorComponent;
