import React from 'react';

type IPageContentProps = React.HTMLProps<HTMLDivElement>;

export const OpttyDirectPageContent: React.FC<IPageContentProps> = ({ children }) => {
  return (
    <>
      <>{children}</>
    </>
  );
};
