import React, { useContext, useEffect } from 'react';
import { CheckoutDataContext } from 'context/merchant-details.context';
import checkoutStyle from './Checkout.module.css';

interface ICheckoutProps {
  formData: any;
  setFormData: any;
  data: any;
}

const Checkout: React.FC<ICheckoutProps> = ({ formData, setFormData, data }) => {
  const { formError } = useContext(CheckoutDataContext);
  useEffect(() => {
    setFormData(data);
  }, []);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className={checkoutStyle.checkoutContainer}>
      <form className={checkoutStyle.addressForm}>
        <div className={checkoutStyle.signleLineField}>
          <label htmlFor="fname">
            First Name<sup>*</sup>
            <input
              className={`${checkoutStyle.widthFit} ${checkoutStyle.marginRFit}`}
              name="fname"
              onChange={handleChange}
              placeholder="Please enter name "
              type="text"
              value={formData?.fname}
            />
            {formError?.fname && (
              <p className={checkoutStyle.formError}>{formError?.fname}</p>
            )}
          </label>
          <label htmlFor="lname">
            Last Name<sup>*</sup>
            <input
              className={checkoutStyle.widthFit}
              name="lname"
              onChange={handleChange}
              placeholder="Please enter last name"
              type="text"
              value={formData?.lname}
            />
            {formError?.lname && (
              <p className={checkoutStyle.formError}>{formError?.lname}</p>
            )}
          </label>
        </div>
        <label htmlFor="email">
          Email<sup>*</sup>
          <input
            name="email"
            onChange={handleChange}
            placeholder="Please enter email"
            type="email"
            value={formData?.email}
          />
          {formError?.email && (
            <p className={checkoutStyle.formError}>{formError?.email}</p>
          )}
        </label>
        <label htmlFor="phoneNumber">
          Phone Number<sup>*</sup>
          <input
            name="phoneNumber"
            onChange={handleChange}
            placeholder="Please enter your Phone Number"
            type="tel"
            value={formData?.phoneNumber}
          />
          {formError?.phoneNumber && (
            <p className={checkoutStyle.formError}>{formError?.phoneNumber}</p>
          )}
        </label>
        <label htmlFor="streetAddress">
          Street Address<sup>*</sup>
          <input
            name="streetAddress"
            onChange={handleChange}
            placeholder="Please enter address"
            type="text"
            value={formData?.streetAddress}
          />
          {formError?.streetAddress && (
            <p className={checkoutStyle.formError}>{formError?.streetAddress}</p>
          )}
        </label>
        <label htmlFor="streetAddress2">
          Street Address 2
          <input
            name="streetAddress2"
            onChange={handleChange}
            placeholder="Please enter address"
            type="text"
            value={formData?.streetAddress2}
          />
        </label>
        <div className={checkoutStyle.signleLineField}>
          <label htmlFor="city">
            City<sup>*</sup>
            <input
              className={`${checkoutStyle.widthFit} ${checkoutStyle.marginRFit}`}
              name="city"
              onChange={handleChange}
              placeholder="Please enter city"
              type="text"
              value={formData?.city}
            />
            {formError?.city && (
              <p className={checkoutStyle.formError}>{formError?.city}</p>
            )}
          </label>
          <label htmlFor="country">
            Country<sup>*</sup>
            <input
              className={checkoutStyle.widthFit}
              name="country"
              onChange={handleChange}
              placeholder="Please enter country"
              type="text"
              value={formData?.country}
            />
            {formError?.country && (
              <p className={checkoutStyle.formError}>{formError?.country}</p>
            )}
          </label>
        </div>
        <div className={checkoutStyle.signleLineField}>
          <label htmlFor="state">
            State
            <input
              className={`${checkoutStyle.widthFit} ${checkoutStyle.marginRFit}`}
              name="state"
              onChange={handleChange}
              placeholder="Please enter state"
              type="text"
              value={formData?.state}
            />
          </label>
          <label htmlFor="postalCode">
            Postal Code
            <input
              className={checkoutStyle.widthFit}
              name="postalCode"
              onChange={handleChange}
              placeholder="Please enter code"
              type="text"
              value={formData?.postalCode}
            />
          </label>
        </div>
        <label htmlFor="region">
          Region
          <input
            name="region"
            onChange={handleChange}
            placeholder="Please enter region"
            type="text"
            value={formData?.region}
          />
        </label>
      </form>
    </div>
  );
};

export default Checkout;
