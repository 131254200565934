import BnplList from 'components/BnplList/BnplList';
// import Footer from 'components/Footer/Footer';
// import Header from 'components/Header/Header';
import MessageContainer from 'components/MessageContainer/MessageContainer';
import OrderAmountCard from 'components/OrderAmount/OrderAmount';
import { PageContent } from 'components/PageContent/PageContent';
import { CheckoutDataContext } from 'context/merchant-details.context';
import qs from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Bnpl } from 'services/interfaces/bnpl-data.interface';
import { IMerchantData } from 'services/interfaces/merchant-data.interface';
import { ICheckoutData, ISessionTokenData } from 'services/interfaces/merchant.interface';
import MerchantService from 'services/merchant.service';
import Checkout from 'components/FieldContainer/Checkout';
import SessionOrderService from 'services/sessionOrder.service';
import { OrderPayload } from 'services/interfaces/order-payload.interface';
import { Error as ValidationError } from 'services/interfaces/checkout-validation.interface';
import Validator from 'components/FieldContainer/Validator';
import LoadingOverlay from 'react-loading-overlay';
// import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
// import SuccessPage from 'pages/successPage';
import GetPaidIndex from 'components/GetPaid/GetPaidIndex';
import OrderDetails from 'components/GetPaid/OrderDetails';
import OpttyDirectHeader from 'components/Header/OpttyDirectHeader';
import { OpttyDirectPageContent } from 'components/OpttyDirectPageContent/OpttyDirectPageContent';
import OrderService from 'services/order.service';
import decode from 'jwt-decode';
import Error from './assets/images/error.svg';
import Loader from './assets/images/loader.svg';
import './App.css';

interface FormData {
  fname: string;
  lname: string;
  email: string;
  phoneNumber: string;
  streetAddress: string;
  streetAddress2: string;
  city: string;
  country: string;
  state: string;
  region: string;
  postalCode: string;
}

function App() {
  const [checkoutData, setCheckoutData] = useState<ICheckoutData>();
  console.log('Checkout Data:', checkoutData);
  const [orderData, setOrderData] = useState<OrderPayload>();
  const [selectedBnpl, setSelectedBnpl] = useState<Bnpl>();
  const [isLoading, setIsLoading] = useState(true);
  const [overAllLoading, setOverAllLoading] = useState(false);
  const [error, setError] = useState<any>();
  const [availableBnpls, setAvailableBnpls] = useState(1);
  const [isDeclined, setIsDeclined] = useState(false);
  const [createOrderError, setCreateOrderError] = useState<Error | unknown>();
  const [isCompletingOrder, setIsCompletingOrder] = useState(false);
  const [isAmountChange, setIsAmountChange] = useState(false);
  const [merchantData, setMerchantData] = useState<IMerchantData | undefined>();
  const defaultValue = checkoutData?.merchant.paymentType;
  const data: FormData = {
    fname: orderData?.customer?.firstName || '',
    lname: orderData?.customer?.lastName || '',
    email: orderData?.customer?.email || '',
    phoneNumber: orderData?.customer?.phoneNumber || '',
    streetAddress: orderData?.billingAddress?.streetAddress || '',
    streetAddress2: orderData?.billingAddress?.streetAddress2 || '',
    city: orderData?.billingAddress?.city || '',
    country: orderData?.billingAddress?.country || '',
    state: orderData?.billingAddress?.state || '',
    region: orderData?.billingAddress?.region || '',
    postalCode: orderData?.billingAddress?.postalCode || '',
  };

  const [formError, setFormError] = useState<ValidationError>();
  const [formData, setFormData] = useState<any>({});

  document.body.setAttribute(
    'style',
    `background: ${checkoutData?.merchant.secondaryColor}`
  );

  const sessionToken = useMemo(
    () => qs.parse(window.location.search).session_token as string,
    []
  );

  const bnplProvider = useMemo(
    () => qs.parse(window.location.search).bnplProvider as string,
    []
  );

  const paymentMethod = useMemo(
    () => qs.parse(window.location.search).payment_method as string,
    []
  );

  const changeOrderAmount = (amount: any) => {
    if (!amount.target.value) {
      setIsAmountChange(true);
      return;
    }
    const tempCheckOutData = { ...checkoutData };
    if (tempCheckOutData.merchant) {
      tempCheckOutData.merchant = {
        ...tempCheckOutData.merchant,
        orderAmount: amount.target.value,
      };
    }
    setCheckoutData(tempCheckOutData as ICheckoutData);
    setIsAmountChange(false);
  };

  const handleCompleteOrder = async () => {
    if (!selectedBnpl) return;
    setIsCompletingOrder(true);
    try {
      const result = Validator.checkoutForm(formData);
      const resultKeys = Object.keys(result);
      if (resultKeys.length > 0) {
        setFormError(result);
        setIsCompletingOrder(false);
        return;
      }
      setFormError(result);
      formData.orderAmount = checkoutData?.merchant.orderAmount;
      const data = await MerchantService.completeOrder(
        sessionToken,
        selectedBnpl?.name,
        formData
      );
      // redirect to BNPL page
      window.location.href = data.redirectUrl;
      setIsCompletingOrder(false);
    } catch (error: unknown) {
      console.log('error completing order', error);
      const merchantReference = (decode(sessionToken) as ISessionTokenData).orderReference;
      const orderData = await OrderService.getOrderData(null, 'DECLINED', selectedBnpl?.name, sessionToken, merchantReference).catch(() => null);
      if (orderData) {
        setIsDeclined(true);
      } else {
        setCreateOrderError(error);
      }
    } finally {
      setIsCompletingOrder(false);
      setOverAllLoading(false);
    }
  };

  useEffect(() => {
    if (!sessionToken) {
      setError('Session token not found');
      setIsLoading(false);
      setOverAllLoading(false);
      return;
    }
    if (paymentMethod) {
      setOverAllLoading(true);
    }

    MerchantService.getMerchantAndBnplDetailsBySessionToken(sessionToken)
      .then(async (checkoutDataResponse) => {
        setAvailableBnpls(checkoutDataResponse.bnplData.bnpls.length);
        const merchantData = await MerchantService.getMerchantMetadataByMerchantId(
          checkoutDataResponse.bnplData.configuration.merchantId
        );
        setMerchantData(merchantData);
        return checkoutDataResponse;
      })
      .then((checkoutDataResponse) => {
        setCheckoutData(checkoutDataResponse);
        setSelectedBnpl(
          checkoutDataResponse.bnplData.bnpls.find(({ name }) => name === paymentMethod)
        );
      })
      .catch((error: unknown) => {
        console.error(error);
        setError(error);
        setOverAllLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
        if (error) {
          setOverAllLoading(false);
        }
      });
    SessionOrderService.getOrderData(sessionToken)
      .then(async (response) => {
        return response;
      })
      .then((response) => {
        setOrderData(response);
        setIsLoading(false);
      })
      .catch((error: unknown) => {
        setError(error);
        setOverAllLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
        if (error) {
          setOverAllLoading(false);
        }
      });
  }, []);

  useEffect(() => {
    if (paymentMethod && selectedBnpl && Object.keys(formData).length) {
      handleCompleteOrder();
    }
  }, [selectedBnpl, formData]);

  useEffect(() => {
    if (availableBnpls < 1 || isDeclined) {
      setOverAllLoading(false);
      setIsLoading(false);
    }
  }, [availableBnpls, isDeclined]);

  return (
    <BrowserRouter>
      <CheckoutDataContext.Provider
        value={{
          checkoutData,
          selectedBnpl,
          setSelectedBnpl: (bnpl: Bnpl | undefined) => setSelectedBnpl(bnpl),
          isLoading,
          setIsLoading,
          completeOrder: handleCompleteOrder,
          error,
          setError,
          isCompletingOrder,
          createOrderError,
          merchantData,
          orderData,
          changeOrderAmount,
          setIsAmountChange,
          isAmountChange,
          formError,
        }}
      >
        <LoadingOverlay
          active={overAllLoading}
          spinner
          styles={{
            overlay: (base: any) => ({
              ...base,
              position: 'fixed',
            }),
          }}
          text="Loading your content..."
        >
          {/* <Header /> */}
          <OpttyDirectHeader />
          <Routes>
            <Route
              element={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <PageContent>
                  {!isLoading && checkoutData && availableBnpls > 0 && !isDeclined && (
                    <>
                      <OrderAmountCard />
                      <Checkout
                        data={!defaultValue && data}
                        formData={formData}
                        setFormData={setFormData}
                      />
                      <BnplList />
                    </>
                  )}

                  {isLoading && (
                    <MessageContainer
                      headerText="Loading..."
                      icon={Loader}
                      isLoader
                      subText="Please do not refresh the page"
                    />
                  )}
                  {availableBnpls < 1 && (
                    <MessageContainer
                      headerText="This payment method is unavailable"
                      icon={Error}
                      subText="Please select an alternative payment method or return to checkout via the CANCEL button"
                    />
                  )}

                  {isDeclined && (
                    <MessageContainer
                      headerText="Unfortunately, your payment was unsuccessful."
                      icon={Error}
                      subText="Please click 'Back to Checkout' to choose an alternative payment method."
                    />
                  )}

                  {error && (
                    <MessageContainer
                      headerText="Error Loading Data"
                      icon={Error}
                      subText="Something went wrong"
                    />
                  )}
                </PageContent>
              }
              path="/"
            />
            <Route
              element={(
                <OpttyDirectPageContent>
                  <>
                    <GetPaidIndex />
                  </>
                  <>
                    {error && (
                      <MessageContainer
                        headerText="Error Loading Data"
                        icon={Error}
                        subText="Something went wrong"
                      />
                    )}
                  </>
                </OpttyDirectPageContent>
              )}
              path="/optty-direct/:customization"
            />
            <Route
              element={(
                <OpttyDirectPageContent>
                  <>
                    <OrderDetails bnplProvider={bnplProvider} value={2} />
                  </>
                  <>
                    {error && (
                      <MessageContainer
                        headerText="Error Loading Data"
                        icon={Error}
                        subText="Something went wrong"
                      />
                    )}
                  </>
                </OpttyDirectPageContent>
              )}
              path="/callback"
            />
            <Route
              element={(
                <MessageContainer
                  headerText="Page Not Found"
                  icon={Error}
                  subText="Something went wrong"
                />
              )}
              path="*"
            />
          </Routes>
          <Footer />
        </LoadingOverlay>
      </CheckoutDataContext.Provider>
    </BrowserRouter>
  );
}

export default App;
